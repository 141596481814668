@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-gray-900;
    }
    input,
    textarea,
    select {
        @apply focus:outline-none;
    }
}

@layer components {
    .avatar {
        @apply w-10 h-10 rounded-full bg-gray-100 text-gray-300;
    }
    .bottomnavbar {
        @apply fixed bottom-0 px-app pt-2 w-full h-20 border-t border-gray-300 bg-white flex;
    }
    .bottomnavbar-button {
        @apply flex-1 h-13 flex flex-col justify-center items-center space-y-1;
    }
    .h1 {
        @apply font-extrabold text-24 text-center;
    }
    .h2 {
        @apply font-semibold text-22;
    }
    .hr {
        @apply h-4 border-none bg-gray-100;
    }
    .list {
        @apply pb-20;
    }
    .list--item {
        @apply px-app py-4 border-b border-gray-100 flex justify-between items-center space-x-3;
    }
    .main {
        @apply mb-13 pt-8 flex flex-col space-y-8;
    }
    .section {
        @apply px-app;
    }
    .tab {
        @apply flex-1 h-12 font-medium text-18 text-gray-400 active:bg-gray-50;
    }
    .tab-active {
        @apply flex-1 h-12 border-b-2 border-gray-900 font-bold text-18 active:bg-gray-50;
    }
    .tab-list {
        @apply px-app border-b border-gray-100 bg-white/90 flex;
    }
    .toast {
        @apply mx-8 py-2 rounded-md bg-gray-900 text-13 text-white text-center;
    }
    .topnavbar {
        @apply sticky top-0 px-app h-topnavbar border-b border-gray-100 bg-white/90 flex items-center;
    }
    .topnavbar-button {
        @apply w-11 h-11 grid place-items-center;
    }
}
